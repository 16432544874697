<template>
  <VApp class="mt-0 pt-0">
    <!--    <NuxtLoadingIndicator style="position: sticky;" />-->
    <div class="container-fluid">
      <HFunnelTopBar :no-offer="true" />
      <div class="row">
        <div class="left-column col-4 d-none d-md-block">
          <div
            ref="mapRef"
            class="map"
          />
          <div class="mentions">
            <NuxtLink
              :to="'/mentions-legales'"
              class="link"
            >
              <div class="mention-text">
                Mentions légales et CGU
              </div>
              <div class="rights">
                © homeloop {{ new Date().getFullYear() }}. Tous droits réservés.
              </div>
            </NuxtLink>
          </div>
        </div>

        <div class="right-column col-12 col-md-8">
          <div class="row">
            <div :class="[fullWidthLg ? 'col-12' : 'col-lg-7', 'col-12', 'col-md-10', 'center-on-md-sm']">
              <slot
                :full-width-lg="(value) => {
                  fullWidthLg = value;
                }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </VApp>
</template>

<script lang="ts" setup>
import { ref, onMounted, watch } from 'vue'
import { useFunnel } from '@/composables/funnel'
import { useGMap } from '@/composables/gmap'

const { getCurrent } = useFunnel()
const { gMapLoader } = useGMap()

const topBarHeight = ref<string>('')
const bottomBarHeight = ref<string>('')
const fullWidthLg = ref(true)

const mapRef = ref<HTMLElement | null>(null)

useHead({
  bodyAttrs: {
    class: 'funnel'
  }
})

onMounted(() => {
  const funnelNavBar = document.querySelector('.funnel-navbar')
  topBarHeight.value = funnelNavBar?.scrollHeight + 'px' || '0px'
  const mentions = document.querySelector('.mentions')
  bottomBarHeight.value = mentions.scrollHeight + 'px' || '0px'
})

watch(
  () => getCurrent('addresses').location,
  async (address) => {
    if (process.server) {
      return
    }

    const { Map } = await gMapLoader.importLibrary('maps')
    const { Marker } = await gMapLoader.importLibrary('marker')
    const position = { lat: address.coordinates[1], lng: address.coordinates[0] }

    if (mapRef.value) {
      const map = new Map(mapRef.value, {
        zoom: 19,
        disableDefaultUI: true,
        gestureHandling: 'none',
        center: position
      })

      // eslint-disable-next-line no-new
      new Marker({
        position,
        map,
        optimized: true
      })
    }
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>

.left-column {
  padding-left: 37px;
  padding-top: 18px;
  position: sticky;
  top: v-bind(topBarHeight);
  height: calc(100vh - v-bind(topBarHeight) - v-bind(bottomBarHeight));

  .map {
    height: 100%;
    width: 100%;
    border-radius: 20px
  }

  .mentions {
    padding-top: 15px;
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.24px;
    text-align: left;
    color: #0a0e34b3;
    padding-bottom: 15px;
    display: flex;
    flex-wrap: wrap;

    .link {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      color: inherit;
      text-decoration: none;
      width: 100%;
    }

    .mention-text,
    .rights {
      white-space: normal;
      flex: 1 1 auto;
    }

    .mention-text {
      text-decoration: underline;
    }

    @media (max-width: 1500px) {
      flex-direction: column; // Aligne les éléments du lien verticalement sur les écrans plus petits
      align-items: flex-start; // Aligne les éléments à gauche
    }
  }
}

  :deep(.v-label) {
  &:not(.v-label--clickable, .v-field-label) {
    font-weight: bold;
  }
}

.right-column {
  padding-top: 18px;
  padding-left: 55px;

  @media (max-width: 768px) {
    padding-left: calc(30px * 0.5);
  }
}

:deep(.v-radio-group > .v-input__control > .v-label) {
  margin-inline-start: unset !important;
}

.center-on-md-sm {
  @media (max-width: 992px) {
    display: flex;
    justify-content: center;
  }
}
</style>
